Vue.component('feed', {
	props: ['feedId'],
	template: `
		<div>
			<div class="feed__wrapper">
				<status v-for="status in statuses" :status-image="status.profile_image_url" :status-icon="status.feed.icon" :status-type="status.type" :status-date="status.date" :status-url="status.url" :status-text="status.text">
					<template v-if="status.type == 'twitter_retweet'" slot="retweeter_name">{{ status.retweeter_name }}</template>
					<template slot="name">{{ status.type == 'rss' ? status.feed.name : status.name }}</template>
					<template slot="screen_name">{{ status.type == 'rss' ? status.url : status.screen_name }}</template>
					<template slot="date">{{ status.date }}</template>
				</status>
			</div>
			<div v-if="statuses != []">
				<button class="feed__more" v-on:click="updateData(true)">Load more</button>
			</div>
		</div>
	`,
	data() {
		return {
			initialized: false,
			statuses: []
		};
	},
	mounted() {
		this.updateData();	
	},
	methods: {
		updateData: function(more = false) {
			var self = this,
				uri = new Uri('https://api.imta.nl/api/v1/lists/statuses');

			// add list id to uri
			uri.addQueryParam('list_id', this.feedId);

			if (this.initialized && this.statuses.length && !more) {
				uri.addQueryParam('since_id', this.statuses[0].id)
			}

			if (this.initialized && this.statuses.length && more) {
				uri.addQueryParam('max_id', this.statuses[this.statuses.length-1].id);
			}

			axios.get(uri.toString())
				.then(function(response){
					var results = response.data.data;

					// Check if there is a result
					if (results) {
						// Check if initialized, then prepend
						if (self.initialized && !more) {
							self.statuses = results.concat(self.statuses);
						} else if (self.initialized && more) {
							self.statuses = (self.statuses).concat(results);
						} else {
							self.statuses = results;
							self.initialized = true;
						}
					
					}
				});

			if (!more) {
				setTimeout(this.updateData, 10000);
			}
		}
	}
});;Vue.component('status', {
	props: ['statusImage', 'statusIcon', 'statusType', 'statusDate', 'statusUrl', 'statusText'],
	template: `
		<transition name="jelly">
			<article :class="'status status--'+trimmedStatusType" v-on:click="navigateTo">
				<header class="status__header" v-if="trimmedStatusType == 'twitter-retweet'">
					<h3 class="status__retweeter"><slot name="retweeter_name"></slot> heeft geretweet</h3>
				</header>
				<section class="status__wrapper">
					<div class="status__content">
						<h1 class="status__title"><slot name="name"></slot></h1>
						<h2 class="status__subtitle" v-if="service == 'twitter'">@<slot name="screen_name"></slot></h2>
						<h2 class="status__source" v-if="service == 'rss'"><a :href="statusUrl">Full story</a></h2>
						<p class="status__text" v-html="linkifiedStatusText"></p>
					</div>
					<figure class="status__photo"><img :src="photo"></figure>
					<figure	class="status__icon"><img :src="'/public/gfx/'+service+'.svg'"></figure>
				</section>
				<footer class="status__footer">
					<time :datetime="statusDate">{{ statusDate | moment }}</time>
				</footer>
			</article>
		</transition>
	`,
	computed: {
		service: function () {
			return this.statusType.split('_').shift();
		},
		photo: function() {
			return this.service == 'twitter' ? this.statusImage : this.statusIcon; 
		},
		trimmedStatusType: function() {
			return this.statusType.replace('_', '-');
		},
		linkifiedStatusText() {
			return this.linkify(this.statusText);
		}
	},
	filters: {
		moment: function(datetime) {
			return moment(datetime, moment.ISO_8601).fromNow();
		}
	},
	methods: {
		linkify: function(text) {
			return linkifyHtml(text, {
				className: 'external-link'
			});
		},
		navigateTo: function(event) {
			if (event.target.tagName.toLowerCase() !== 'a') {
				window.open(this.statusUrl,'_blank');
			}
			return true;
		}
	}
});;new Vue({
	el: '#app',
	data: {
		statusses: [],
		tab: 1
	},
	computed: {
		tab_1: function() {
			return (this.tab == 1) ? 'feed--active' : '';
		},
		tab_2: function() {
			return (this.tab == 2) ? 'feed--active' : '';
		},
		tab_3: function() {
			return (this.tab == 3) ? 'feed--active' : '';
		},
		tab_4: function() {
			return (this.tab == 4) ? 'feed--active' : '';
		}
	}
});